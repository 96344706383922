import React, { useState, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { IconButton, Typography, Menu, MenuItem, CssBaseline } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LightMode from '@mui/icons-material/LightMode';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Home from './components/Home';
import About from "./components/About";
import Contact from "./components/Contact";
import PrivacyPolicy from "./components/PrivacyPolicy";
import './App.css';
import LatestNews from "./components/LatestNews";
import BusinessNews from "./components/BusinessNews";
import TopNews from "./components/TopNews";

const App = () => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [darkMode, setDarkMode] = useState(prefersDarkMode);
    const [anchorEl, setAnchorEl] = useState(null);

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: darkMode ? 'dark' : 'light',
                    primary: {
                        main: '#ff6700', // Neon orange color
                    },
                },
                typography: {
                    fontFamily: darkMode ? 'Courier Prime, monospace' : 'Roboto, sans-serif',
                },
            }),
        [darkMode],
    );

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleThemeToggle = () => {
        setDarkMode((prevMode) => !prevMode);
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className={darkMode ? 'dark-mode app-container' : 'light-mode app-container'}>
                <Router>
                    <div className="content-container">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                            <Route path="/top" element={<TopNews />} />
                            <Route path="/business" element={<BusinessNews />} />
                            <Route path="/latest" element={<LatestNews />} />

                        </Routes>
                    </div>
                    <footer className="footer">
                        <Typography variant="body1" style={{marginLeft: '20px'}}>
                            News v2 © {new Date().getFullYear()}
                        </Typography>
                        <div className="footer-menu">
                            <IconButton edge="end" color="inherit" onClick={handleMenuOpen}>
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                            >
                                <MenuItem onClick={handleMenuClose} component={Link} to="/">News v2</MenuItem>
                                <MenuItem onClick={handleMenuClose} component={Link} to="/latest">Latest News</MenuItem>
                                <MenuItem onClick={handleMenuClose} component={Link} to="/about">About</MenuItem>
                                <MenuItem onClick={handleMenuClose} component={Link} to="/contact">Contact</MenuItem>
                                <MenuItem onClick={handleMenuClose} component={Link} to="/privacy-policy">Privacy Policy</MenuItem>
                            </Menu>
                            <IconButton edge="end" color="inherit" onClick={handleThemeToggle}>
                                {darkMode ? <LightMode style={{ fontSize: '1.5rem' }} /> : <Brightness3Icon style={{ fontSize: '1.5rem' }} />}
                            </IconButton>
                        </div>
                    </footer>
                </Router>
            </div>
        </ThemeProvider>
    );
};

export default App;
