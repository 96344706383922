import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, ListItemText } from '@mui/material';
import SpeedIcon from '@mui/icons-material/Speed';
import AudioPlayer from 'react-h5-audio-player';
import Tooltip from "@mui/material/Tooltip"; // Adjust the import based on your audio player

const speeds = [0.75, .9, 1, 1.25, 1.5, 2];

const SpeedControl = ({ audioRef }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [playbackRate, setPlaybackRate] = useState(1);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSpeedChange = (speed) => {
        setPlaybackRate(speed);
        audioRef.current.audio.current.playbackRate = speed;
        handleClose();
    };

    return (
        <div>
            <Tooltip title={"Playback Speed"}>
            <IconButton onClick={handleClick} >
                <SpeedIcon />
            </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {speeds.map((speed) => (
                    <MenuItem
                        key={speed}
                        selected={speed === playbackRate}
                        onClick={() => handleSpeedChange(speed)}
                    >
                        <ListItemText primary={`${speed}x`} />
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default SpeedControl;
