import React, { useState } from 'react';
import { Alert, Collapse, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const AIWarningAlert = () => {
    const [open, setOpen] = useState(true); // Alert is initially open

    return (
        <Collapse in={open} style={{marginTop: '10px'}}>
            <Alert
                severity="warning"
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => setOpen(false)}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                Notice: This content has been generated using artificial intelligence. Please ensure that you verify the information with trusted sources.
            </Alert>
        </Collapse>
    );
};

export default AIWarningAlert;
