import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Accordion, AccordionSummary, AccordionDetails, Typography, CircularProgress, Box, Pagination } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const TechNews = () => {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentTime, setCurrentTime] = useState('');
    const [page, setPage] = useState(1);
    const newsPerPage = 10; // Number of articles per page

    // const url = 'http://localhost:8000/api/technews/';
    const url = 'https://api.newsv2.com/api/technews/';


    useEffect(() => {
        axios.get(url)
            .then(response => {
                setNews(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching news:', error);
                setLoading(false);
            });

        const now = new Date();
        const dateString = now.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
        const timeString = now.toLocaleTimeString();
        setCurrentTime(`${dateString}: ${timeString}`);
    }, []);

    const openArticle = (url) => {
        window.open(url, '_blank'); // Open in new tab
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const paginatedNews = news.slice((page - 1) * newsPerPage, page * newsPerPage); // Logic for pagination

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ margin: '0 auto', marginTop: "10px", maxWidth: '800px' }}>
            <Box sx={{ margin: '0 auto', maxWidth: '800px', textAlign: 'center' }}>
                <Typography variant="h4" gutterBottom>
                    Tech News
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                    {currentTime}
                </Typography>
            </Box>
            <Box display="flex" justifyContent="center" sx={{ marginTop: 4 }}>
                <Pagination
                    count={Math.ceil(news.length / newsPerPage)} // Total number of pages
                    page={page}
                    onChange={handlePageChange}
                    variant="outlined"
                    color="primary"
                />
            </Box>

            {paginatedNews.map((article, index) => (
                <Accordion key={index} style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                    >
                        <Typography variant="body1" style={{fontSize: '1.2rem', marginBottom: '1rem'}}>
                            {article.title}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1" color="text.secondary">
                            {article.description}
                        </Typography>
                        <br />
                        <Typography variant="body2" color="text.secondary" display="block" gutterBottom>
                            Published at: {article.publishedAt}
                        </Typography>
                        <Box display="flex" justifyContent="flex-end">
                            <Button
                                startIcon={<OpenInNewIcon />}
                                onClick={() => openArticle(article.url)}
                                style={{ marginTop: '1rem' }}
                            >
                                Open Article
                            </Button>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            ))}

            <Box display="flex" justifyContent="center" sx={{ marginTop: 4,marginBottom: 4 }}>
                <Pagination
                    count={Math.ceil(news.length / newsPerPage)} // Total number of pages
                    page={page}
                    onChange={handlePageChange}
                    variant="outlined"
                    color="primary"
                />
            </Box>
        </Box>
    );
};

export default TechNews;
