import React from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 1000,
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 0,
    display: 'flex',
    flexDirection: 'column',
};

const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ddd',
    padding: '16px 24px',
    backgroundColor: 'background.paper',
    position: 'sticky',
    top: 0,
    zIndex: 1,
};

const contentStyle = {
    padding: '16px 24px',
    overflowY: 'auto',
    flexGrow: 1,
};

const ModalComponent = ({ open, handleClose, title, content }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={style}>
                <Box sx={headerStyle}>
                    <Typography id="modal-title" variant="h6" component="h6">
                        {title}
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={contentStyle}>
                    {/*<Typography id="modal-description">*/}
                        {content}
                    {/*</Typography>*/}
                </Box>
            </Box>
        </Modal>
    );
};

export default ModalComponent;
