// import React, { useEffect, useState, useRef } from 'react';
// import axios from 'axios';
// import { Container, Box, IconButton, Typography,Button, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
// import SkipNextIcon from '@mui/icons-material/SkipNext';
// import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import PauseIcon from '@mui/icons-material/Pause';
// import { styled } from '@mui/system';
// import AudioMotionAnalyzer from 'audiomotion-analyzer';
// import AudioPlayer from 'react-h5-audio-player';
// import 'react-h5-audio-player/lib/styles.css';
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Container, Box, IconButton, Typography, Button, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { styled } from '@mui/system';
import AudioMotionAnalyzer from 'audiomotion-analyzer';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { RHAP_UI } from 'react-h5-audio-player';
// import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SpeakerNotes from '@mui/icons-material/SpeakerNotes';
import SpeakerNotesOff from '@mui/icons-material/SpeakerNotesOff';
import { useTheme } from '@mui/material/styles';
import SpeedControl from "./SpeedControl";
import DescriptionIcon from "@mui/icons-material/Description";
import ModalComponent from "./Modal";
// import logo from '../assets/wet-sand-logo.png';


const StyledContainer = styled(Container)(({ theme }) => ({
    padding: theme.spacing(2),
}));

const StyledAudioControls = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    // marginTop: theme.spacing(2),
}));

const TranscriptBox = styled(Box)(({ theme }) => ({
    // marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    maxHeight: '300px',
    overflowY: 'auto'
}));

const LiveTranscriptBox = styled(Box)(({ theme }) => ({
    minHeight: '30px',
    maxHeight: '30px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const ScrollingText = styled(Typography)(({ theme }) => ({
    display: 'inline-block',
    whiteSpace: 'nowrap',
    transition: 'transform 1.2s linear',
    fontSize: '20px'
}));

const CustomAudioPlayer = React.forwardRef(({ src, onPlay, onPause, onEnded, onTimeUpdate }, ref) => (
    <AudioPlayer
        ref={ref}
        src={src}
        onPlay={onPlay}
        onPause={onPause}
        onEnded={onEnded}
        onListen={onTimeUpdate}
        showJumpControls={true}
        layout="stacked"
        autoPlayAfterSrcChange={false}
        crossOrigin="anonymous"

        style={{ backgroundColor: 'inherit', color: 'inherit' }}
        // className="custom-audio-player"

    />
));




function Contact(props) {

    const [isPlaying, setIsPlaying] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(50);
    const [showTranscript, setShowTranscript] = useState(true);
    const audioRef = useRef(null);
    const containerRef = useRef(null);
    const audioMotionRef = useRef(null);
    const [category, setCategory] = useState('Business');
    const [transcript, setTranscript] = useState('Hello, Thank you for visiting News v2! Our mission is to provide news in an innovative and valuable way. If you have any questions, comments, or concerns, please don’t hesitate to send an email to code.by.dom@gmail.com. I will do my best to respond promptly. Best regards, Dom' );
    const [openModal, setOpenModal] = useState(false);


    const theme = useTheme();

    const color = theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)';




    const initializeAudioMotionAnalyzer = () => {
        if (audioRef.current && containerRef.current && !audioMotionRef.current) {
            audioMotionRef.current = new AudioMotionAnalyzer(containerRef.current, {
                source: audioRef.current.audio.current,

                height: 300,
                ansiBands: false,
                showScaleX: false,
                bgAlpha: 0,
                overlay: true,
                mode: 7,
                frequencyScale: "bark",
                showPeaks: true,
                smoothing: 0.1,
                ledBars: true,
                mirror:0,
                gradient:"orangered",

            });

        }
    };
    const handlePlay = () => {
        if (audioRef.current && audioRef.current.audio.current.readyState >= 2) {
            initializeAudioMotionAnalyzer();
            setIsPlaying(true);
            audioRef.current.audio.current.play();
        }
    };

    const handlePause = () => {
        setIsPlaying(false);
    };

    const handleEnded = () => {
        setIsPlaying(false);
        if (audioRef.current) {
            audioRef.current.audio.current.currentTime = 0;
            audioRef.current.audio.current.pause();
        }
    };
    const handleTimeUpdate = () => {
        if (audioRef.current) {
            const currentTime = audioRef.current.audio.current.currentTime;
            const duration = audioRef.current.audio.current.duration;

            const progress = currentTime / duration;
            const transcriptLength = transcript.length;
            const displayedLength = Math.floor(progress * transcriptLength);
            const newPosition = 50 - (progress * 101);
            if (!isNaN(newPosition)) {
                setScrollPosition(newPosition);
            }
        }
    };
    const toggleTranscriptVisibility = () => {
        setShowTranscript((prev) => !prev);
    };


    const handleModalOpen = () => {
        setOpenModal(true);
        setShowTranscript(false);

    };

    const handleModalClose = () => {
        setOpenModal(false);
    };


    return (
        <div>
            <StyledContainer>

                <Typography
                     variant="h6"
                     gutterBottom
                     sx={{
                         color: color, // Light grey color with low opacity
                         textAlign: 'center', // Center the text
                         // fontStyle: 'italic' // Optional: Add some style to make it more mysterious
                     }}
                 >
                     Contact
                 </Typography>

                <Box ref={containerRef} style={{ width: '100%', height: '300px',  }} />



                <LiveTranscriptBox>
                    {isPlaying && (
                        <ScrollingText variant="body1" style={{ transform: `translateX(${scrollPosition}%)` }}>
                            {transcript}
                        </ScrollingText>
                    )}
                </LiveTranscriptBox>
                <Box display="flex" justifyContent="center" alignItems="center" marginTop="15px" gap=".5rem">
                    <CustomAudioPlayer

                        ref={audioRef}
                        src={"https://d3k64dniyspny7.cloudfront.net/contact.mp3"}
                        onPlay={handlePlay}
                        onPause={handlePause}
                        onEnded={handleEnded}
                        onTimeUpdate={handleTimeUpdate}
                    />


                </Box>
                <Box display="flex" justifyContent="center" style={{marginTop:"5px", marginBottom:"5px"}} gap="1rem" >

                    <Tooltip title={showTranscript ? 'Hide Transcript' : 'Show Transcript'}>
                        <IconButton onClick={toggleTranscriptVisibility}>
                            {showTranscript ? <SpeakerNotesOff /> : <SpeakerNotes />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Full Screen View Transcript"}>

                        <IconButton  onClick={handleModalOpen}>
                            <DescriptionIcon />
                        </IconButton>
                    </Tooltip>
                    <SpeedControl audioRef={audioRef} />
                </Box>
                {showTranscript && (
                    <TranscriptBox>
                        <Typography variant="body1">{transcript}</Typography>
                    </TranscriptBox>
                )}
                <ModalComponent open={openModal} handleClose={handleModalClose} title={"Contact"} content={transcript} />


            </StyledContainer>

        </div>
    );
}

export default Contact;
