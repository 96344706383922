import React, { useState } from 'react';
import { Button, Box, Typography } from '@mui/material';

// Import your news components here
import TopNews from './TopNews';
import BusinessNews from './BusinessNews';
import TechNews from './TechNews';
import FDANews from './FDANews';
import SportsNews from './SportsNews';
import WorldNews from "./WorldNews";
import HealthNews from './HealthNews';
import ScienceNews from "./ScienceNews";

const NewsButtons = () => {
    const [selectedNews, setSelectedNews] = useState(null);

    const handleButtonClick = (newsCategory) => {
        setSelectedNews(newsCategory);
    };

    const renderNewsComponent = () => {
        switch (selectedNews) {
            case 'top-news':
                return <TopNews />;
            case 'business-news':
                return <BusinessNews />;
            case 'tech-news':
                return <TechNews />;
            case 'fda-news':
                return <FDANews />;
            case 'sports-news':
                return <SportsNews />;
            case 'health-news':
                return <HealthNews />;
            case 'world-news':
                return <WorldNews />;
            case 'science-news':
                return <ScienceNews />;
            default:
                return                     <Box display="flex" textAlign="center" marginTop={4}>
                    <Typography variant="h6">Select a Category </Typography></Box>;
        }
    };

    return (
        <Box>
            <Box display="flex" justifyContent="center" gap={1} flexWrap="wrap" marginBottom={4} marginTop={2}>
                <Button
                    variant="outlined"
                    onClick={() => handleButtonClick('top-news')}
                    sx={{
                        padding: { xs: '8px 16px', md: '16px 24px' },  // Smaller on mobile, larger on desktop
                        fontSize: { xs: '0.9rem', md: '1.2rem' },       // Smaller font on mobile, larger on desktop
                        fontWeight: 'bold',
                        borderWidth: '2px',
                    }}
                >
                    Top News
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => handleButtonClick('world-news')}
                    sx={{
                        padding: { xs: '8px 16px', md: '16px 24px' },
                        fontSize: { xs: '0.9rem', md: '1.2rem' },
                        fontWeight: 'bold',
                        borderWidth: '2px',
                    }}
                >
                    World News
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => handleButtonClick('business-news')}
                    sx={{
                        padding: { xs: '8px 16px', md: '16px 24px' },
                        fontSize: { xs: '0.9rem', md: '1.2rem' },
                        fontWeight: 'bold',
                        borderWidth: '2px',
                    }}
                >
                    Business News
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => handleButtonClick('tech-news')}
                    sx={{
                        padding: { xs: '8px 16px', md: '16px 24px' },
                        fontSize: { xs: '0.9rem', md: '1.2rem' },
                        fontWeight: 'bold',
                        borderWidth: '2px',
                    }}
                >
                    Tech News
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => handleButtonClick('sports-news')}
                    sx={{
                        padding: { xs: '8px 16px', md: '16px 24px' },
                        fontSize: { xs: '0.9rem', md: '1.2rem' },
                        fontWeight: 'bold',
                        borderWidth: '2px',
                    }}
                >
                    Sports News
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => handleButtonClick('health-news')}
                    sx={{
                        padding: { xs: '8px 16px', md: '16px 24px' },
                        fontSize: { xs: '0.9rem', md: '1.2rem' },
                        fontWeight: 'bold',
                        borderWidth: '2px',
                    }}
                >
                    Health News
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => handleButtonClick('science-news')}
                    sx={{
                        padding: { xs: '8px 16px', md: '16px 24px' },
                        fontSize: { xs: '0.9rem', md: '1.2rem' },
                        fontWeight: 'bold',
                        borderWidth: '2px',
                    }}
                >
                    Science News
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => handleButtonClick('fda-news')}
                    sx={{
                        padding: { xs: '8px 16px', md: '16px 24px' },
                        fontSize: { xs: '0.9rem', md: '1.2rem' },
                        fontWeight: 'bold',
                        borderWidth: '2px',
                    }}
                >
                    FDA News
                </Button>
            </Box>


            <Box display="flex" justifyContent="center">
                {renderNewsComponent()}
            </Box>
        </Box>
    );
};

export default NewsButtons;
