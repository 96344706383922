import React from 'react';
// import AudioPlayer from "./audioPlayer";
import AudioPlayerPro from "./audioPlayer";

function Home(props) {
    return (
        <div><AudioPlayerPro/></div>
    );
}

export default Home;